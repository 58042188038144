<script setup>
const emit = defineEmits(['update:modelValue', 'focusout', 'focusin', 'change', 'mounted'])

const props = defineProps({
  modelValue: String | Number,
  name: String,
  placeholder: String,
  focused: Boolean,
  disabled: Boolean,
  id: String,
  type: {
    type: String,
    default: 'text'
  }
})

const inputRef = ref(null);

watch(() => props.focused, (val) => {
  if (val) {
    inputRef.value?.focus();
  }
});


watch(() => props.focused, (val) => {
  if (val) {
    const input = document.querySelector('input');
    input.focus();
  }
});

const updateValue = (event) => {
  if (props.disabled) {
    return;
  }

  const target = event.target
  emit('update:modelValue', target.value);
};

const component = computed(() => {
  return props.type === 'textarea' ? 'textarea' : 'input';
});


onMounted(() => {
  emit('mounted', inputRef.value)
});
</script>

<template>
  <component :is="component" :name="props.name" :type="type"
             ref="inputRef"
             :disabled="disabled"
             :id="id"
             :value="props.modelValue"
             @change="$emit('change',$event)"
             @focusin="$emit('focusin',$event)"
             @focusout="$emit('focusout',$event)"
             @input="updateValue"
             :placeholder="props.placeholder"/>
</template>

<style scoped lang="scss">
@import 'assets/scss/variables.scss';

input, textarea {
  font-size: 14px;
  border: 0;
  outline: none;
  width: 100%;
  background: transparent;
  margin-top: 5px;
  padding-left: 2px;
  color: black !important;
  opacity: 1;

  &::placeholder {
    color: $light-black;
  }
}
</style>
